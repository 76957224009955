import {useEffect, useState} from "react";
import {Button, Input, message, Popconfirm, Space, Switch, Table, Typography} from 'antd'
import axios from "axios";
import qs from "qs";
import {BASE_URL} from "../../services/server";
import {useNavigate} from "react-router-dom";
import DateHelper from "../../helpers/date";

const { Column } = Table;

const UserManagement = (props) => {
    const [tableLoading, setTableLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            position: ["topRight", "bottomRight"],
            pageSizeOptions: [5, 10, 25, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) => `Total users: ${total}`,
            current: 1,
            pageSize: 10,
        },
        sort_field: 'name',
        sort_order: 'ascend',
    })

    const [filterSearch, setFilterSearch] = useState('')
    const [users, setUsers] = useState([])
    const [shownSharedPanel, setShownSharedPanel] = useState(false)
    const [sharedUsers, setSharedUsers] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [videoId, setVideoId] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        getUsers();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters),
    ]);

    useEffect(() => {
        if ((filterSearch.length > 2) || (filterSearch === '')) {
            getUsers();
        }
    }, [filterSearch]);

    const getUserParams = (params) => ({
        page: params.pagination?.current,
        per_page: params.pagination?.pageSize,
        sort_field: params?.sortField,
        sort_order: params?.sortOrder,
        search_query: filterSearch,
    });

    const getUsers = () => {
        setTableLoading(true);
        axios.get(BASE_URL + `user?${qs.stringify(getUserParams(tableParams))}`)
            .then((res) => {
                setTableLoading(false);
                const userItems = res.data.items.map(obj =>
                    ({
                        ...obj,
                        is_sls: obj.sls_id !== null
                    })
                );
                setUsers(userItems)
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: res.data.total_items,
                        showTotal: (total, range) => `Total users: ${total}`,
                    },
                });
            })
            .catch(() => {
                setTableLoading(false)
            })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
          pagination,
          filters,
          sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
          sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setUsers([]);
        }
    }

    const setAdmin = (userId) => {
        axios.post(BASE_URL + `user/${userId}/admin`)
            .then((res) => {
                getUsers()
            })
    }

    const removeAdmin = (userId) => {
        axios.delete(BASE_URL + `user/${userId}/admin`)
            .then((res) => {
                getUsers()
            })
    }

    const downloadUserData = () => {
        axios({
            url: BASE_URL + 'user/data',
            method: 'GET',
            responseType: 'blob',
        }).then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            const filename = resp.headers["content-disposition"].split('filename="')[1].split('";')[0]

            link.href = url;
            link.setAttribute('download', filename);

            document.body.appendChild(link);
            link.click();
        });
    }

    const testPopulate = () => {
        axios({
            url: BASE_URL + 'user/test',
            method: 'GET',
        }).then(resp => {

        });
    }

    const manageVideoTag = (e) => {
    }

    return (
    <div className="">
        <div className="mb-2 flex flex-col md:flex-row">
            <div className="w-full md:w-3/4">
                <Typography.Title level={5}>Search User</Typography.Title>
                <Input
                    value={filterSearch}
                    placeholder="Search user by name"
                    onChange={(e) => setFilterSearch(e.currentTarget.value) }
                />
            </div>
            <div className="flex items-center justify-end w-full md:w-1/4">
                <Button onClick={downloadUserData}>Download Data</Button>
            </div>
        </div>
        <Table dataSource={users}
               rowKey="uuid"
               loading={tableLoading}
               pagination={tableParams.pagination}
               onChange={handleTableChange}
        >
            <Column
                title="Name"
                sorter={true}
                width="20%" dataIndex="name" key="name"
            />
            <Column
                title="Email"
                sorter={true}
                dataIndex="email" key="email"
            />
            <Column
                title="Role"
                sorter={true}
                dataIndex="role"
                render={(_, record) => (
                    <Space size="middle">
                        {
                            record.role === 0 ? "Super Admin" :
                                record.role === 1 ? "Admin" :
                                    record.role === 2 ? "Sub-Admin" :
                                        record.role === 3 ? "Normal User" : "N/A"
                        }
                    </Space>
                )}
            />

            <Column
                title="School"
                sorter={true}
                dataIndex="school"
                render={(_, record) => (
                    <Space size="middle" className="">
                        {
                            (
                                (record.school !== null) &&
                                (record.school.name !== null)
                            ) ? record.school.name : ''
                        }
                    </Space>
                )}
            />
            <Column title="First Login"
                sorter={true}
                dataIndex="first_login"
                render={(_, record) => (
                    <Space size="middle">
                        {
                            DateHelper.getDateString(record.registered_on)
                        }
                    </Space>
                )}
            />
            <Column title="Last Login"
                sorter={true}
                dataIndex="last_login"
                render={(_, record) => (
                    <Space size="middle">
                        {
                            DateHelper.getDateString(record.last_login)
                        }
                    </Space>
                )}
            />
            <Column title="Usage (GB)"
                sorter={true}
                dataIndex="storage_size"
                render={(_, record) => (
                    <Space size="middle">
                        {(record.storage_size / (1024 ** 3)).toFixed(2)}
                    </Space>
                )}
            />
            <Column
                title="Action"
                key="action"
                render={(_, record) => (
                    <Space size="middle">
                    {
                    (record.role === 1) ?
                    <Popconfirm
                        title="Remove Admin"
                        description="Are you sure to remove admin role for this user?"
                        onConfirm={() => { removeAdmin(record.uuid) }}
                        okButtonProps={{ className: "bg-activeBlue"}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className="cursor-pointer hover:text-activeBlue"
                        >
                            Remove Admin
                        </div>
                    </Popconfirm>
                    :
                    <Popconfirm
                        title="Make Admin"
                        description="Are you sure to grant admin role to this user?"
                        onConfirm={() => { setAdmin(record.uuid) }}
                        okButtonProps={{ className: "bg-activeBlue"}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className="cursor-pointer hover:text-activeBlue"
                        >
                            Make Admin
                        </div>
                    </Popconfirm>
                    }
                    </Space>
                )}
            />
        </Table>
    </div>
    )
}

export default UserManagement
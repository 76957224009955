import {useEffect, useState} from "react";
import {Button, Input, message, Space, Switch, Table} from 'antd'
import axios from "axios";
import {BASE_URL} from "../../services/server";
import {useNavigate} from "react-router-dom";

const { Column, ColumnGroup } = Table;

const StorageManagement = (props) => {
    const [totalFiles, setTotalFiles] = useState(0)
    const [totalSize, setTotalSize] = useState(0)

    useEffect(() => {
        getPersonalStorage()
    }, [])

    const getPersonalStorage = () => {
        axios.get(BASE_URL + `user/storage`)
            .then((res) => {
                setTotalFiles(res.data.total_files)
                setTotalSize(res.data.total_size)
            })
    }

    return (
    <div className="">
        <p>Total video storage allowed per user: 4GB</p>
        <p>My total video storage used: {(totalSize / (1024 ** 3) ).toFixed(2) } GB</p>
        <p>My total number of uploaded video(s): { totalFiles }</p>
        {
        totalSize > (4 * (1024 ** 3)) &&
        <p className="mt-4 text-red-700">Please manage your storage as it has exceeded the 4GB storage limit.</p>
        }
    </div>
    )
}

export default StorageManagement
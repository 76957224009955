import LadderExperimentManagement from "./LadderExperimentManagement"
import LadderUserManagement from "./LadderUserManagement"
import StorageManagement from "./StorageManagement"
import TagManagement from "./TagManagement"
import UserManagement from "./UserManagement"
import MyStorage from "./MyStorage"
import VideoManagement from "./VideoManagement"


const ManagementPanel = {
    StorageManagement,
    TagManagement,
    UserManagement,
    VideoManagement,
    LadderExperimentManagement,
    LadderUserManagement,
    MyStorage,
}

export default ManagementPanel;
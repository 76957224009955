
import React, { useEffect, useState } from "react";
import uploadLogo from "../assets/uploadLogo.svg";
import Input from "antd/es/input/Input";
import {Select, Button, Avatar, Upload, Image, Tabs, message, Checkbox} from "antd";
import { PlusOutlined, UserOutlined } from '@ant-design/icons';

import Sidebar from "../components/Sidebar";

import { BASE_URL } from "../services/server"
import { API_URL } from "../services/video.services";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ListMyVideo from "../components/videos/ListMyVideo";
import {ACCESS_TOKEN_NAME} from "../services/auth.services";
import Layout from "./Layout";
import {checkLogin} from "../store/auth";

const { Search } = Input;


export default function Profile() {
    const { user } = useSelector((state) => state.auth);
    const [schools, setSchools] = useState([])
    const [email, setEmail] = useState("")
    const [emailStatus, setEmailStatus] = useState("")
    const [schoolStatus, setSchoolStatus] = useState("")
    const [selectedSchool, setSelectedSchool] = useState("")
    const [notificationSubscription, setNotificationSubscription] = useState(false)

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const dispatch = useDispatch();

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
    });

    const [fileList, setFileList] = useState([]);

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    // const handleFileChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const uploadProps = {
        name: 'file',
        action: BASE_URL + "user/avatar",
        headers: {
            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_NAME)}`,
        },
        accept: 'image/*',
        multiple: false,
        maxCount: 1,

        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },

        async beforeUpload(file) {
            // console.log(file)
            setFileList([file])

            const reader = new FileReader();

            reader.onload = e => {
                setPreviewImage(e.target.result);
            };
            reader.readAsDataURL(file)
        //
        //     return false;
        },

        onRemove: (file) => {
            setFileList([])

            //   const index = state.fileList.indexOf(file);
            //   const newFileList = state.fileList.slice();
            //   newFileList.splice(index, 1);
            //   setFileList(newFileList)
        },

        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    useEffect(() => {
        searchSchool()
    }, [])

    useEffect(() => {
        if (user) {
            if ((user.school) && (user.school.uuid))
                setSelectedSchool(user.school.uuid)

            if (user.email) setEmail(user.email)

            if (user.subscribe_email_notification)
                setNotificationSubscription(user.subscribe_email_notification)
        }
    }, [user])

    const searchSchool = () => {
        axios.get(
            BASE_URL + "school",
        )
        .then((resp) => {
            let optSchools = resp.data.map((sch) => ({
                value: sch.uuid,
                label: sch.name
            }))
            const actOptions = optSchools.filter(sch => sch.label !== 'Others')
            const altOptions = optSchools.filter(sch => sch.label === 'Others').map(opt => ({
                ...opt,
                label: opt.label + ' (Select this if you are not able to find your school or workplace in the list.)'
            }))

            optSchools = [
                ...altOptions,
                ...actOptions
            ]

            setSchools(optSchools)
        })
        .catch((err) => {
            message.success("Could not fetch list of schools.")
        })
    }

    const setupProfile = () => {
        axios.post(
            BASE_URL + "user/setup",
            {
                email,
                school: selectedSchool,
                email_notification: notificationSubscription
            }
        )
        .then((resp) => {
            message.success("Your profile has been updated")
            dispatch(checkLogin())
        })
        .catch((err) => {
            if (err.response.status === 409) {
                message.error("Email has been used in another account.");
            }
            else {
                message.error(err.message)
            }
        })
    }

    const onChange = (value) => {
        const results = schools.filter(sch => sch.value === value)
        setSelectedSchool(value)
    };

    const validateEmail = (text) => {
        const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (validRegex.test(text)) return true

        return false
    }
    const validateSchool = (schoolId) => {
        try {
            const results = schools.filter(sch => sch.value === schoolId)
            if (results.length > 0) return true
        }
        catch (err) {}

        return false
    }
    const onSubscriptionChange = (e) => {
        setNotificationSubscription(e.target.checked)
    }

    const onSubmit = () => {
        const isEmailValid = validateEmail(email)
        const isSchoolValid = validateSchool(selectedSchool)

        setEmailStatus( isEmailValid ? "" : "error")
        setSchoolStatus(isSchoolValid ? "" : "error")

        if (isEmailValid && isSchoolValid) {
            setupProfile()
        }
    }

    return(
        <Layout className="px-4">
            <div className="mt-4 w-full flex items-center mb-4">
                <div className="flex w-36 overflow-hidden">
                    <Upload {...uploadProps}>
                        <div
                            className="border-2 border-gray-600 rounded-full flex items-center justify-center">
                            <div className="overflow-hidden border-4 border-transparent rounded-full w-32 h-32 flex items-center justify-center">
                            {
                                fileList.length !== 0 ?
                                <img
                                    alt="preview"
                                    style={{
                                    width: '100%',
                                    }}
                                    src={previewImage}
                                />
                                :
                                user && user.profile_pic && user.profile_pic.length > 0 ?
                                <img
                                    alt="user"
                                    style={{
                                    width: '100%',
                                    }}
                                    src={user.profile_pic}
                                />
                                :
                                <Avatar
                                    className="flex justify-center items-center bg-transparent text-gray-600"
                                    size={150} icon={<UserOutlined />}
                                />
                            }
                            </div>
                        </div>
                    </Upload>
                </div>
                <div className="flex-1 flex flex-col ml-2">
                    <p className="mb-2">Name</p>
                    <Input
                        disabled={true}
                        value={user ? user.name : ""}
                    />
                </div>
            </div>
            <div className="flex flex-col mb-8">
                <p className="mb-2">
                    Email
                    <span className="ml-2 text-sm text-gray-600">(yourname@schools.gov.sg or yourname@moe.gov.sg or work email address)</span>
                </p>
                <Input
                    placeholder="Enter your email"
                    status={emailStatus}
                    value={email}
                    onChange={(e) => { setEmail(e.currentTarget.value.toLowerCase()) }}
                />
                {
                    emailStatus.length > 0 &&
                    <span className="text-sm text-red-600 mt-1">
                        Please enter a valid email address
                    </span>
                }
            </div>
            <div className="flex flex-col mb-8">
                <p className="mb-2">School</p>
                <Select
                    showSearch
                    className="w-full "
                    placeholder="Select a school"
                    optionFilterProp="children"
                    status={schoolStatus}
                    onChange={onChange}
                    value={selectedSchool}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={schools}
                  />
                {
                    schoolStatus.length > 0 &&
                    <span className="text-sm text-red-600 mt-1">
                        Please select a valid school
                    </span>
                }
            </div>
            <div className="flex flex-col mb-8">
                <Checkbox
                    checked={notificationSubscription}
                    onChange={onSubscriptionChange}
                >
                    Subscribe to email notifications
                </Checkbox>
            </div>
            <div className="flex mb-8">
                <Button
                    type="primary" htmlType="submit"
                    className="bg-primaryBlue h-8"
                    onClick={onSubmit}>
                    <div className="flex flex-row justify-center items-center px-1">
                        <div className="text-uploadVideoButton">Submit</div>
                    </div>
                </Button>
            </div>
        </Layout>
    )
} 
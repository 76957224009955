import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Image, Dropdown } from "antd";
import { SmileOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Space } from 'antd';
import covaapd_logo from "../assets/covaapdLogo.svg";
import sidebar_video from "../assets/sidebar_video.svg";
import sidebar_admin from "../assets/sidebar_admin.svg";
import { useSelector } from "react-redux";

const items = [
    {
      key: '1',
      label: (
        <NavLink to="/profile"  rel="noopener noreferrer">
          Profile
        </NavLink>
      ),
      icon: <SmileOutlined />,
    },
    {
      key: '2',
      label: (
        <a rel="noopener noreferrer" href="/logout">
          Logout
        </a>
      ),
      
    },
];

const Sidebar = (props) => {
    const { user } = useSelector((state) => state.auth);
    
    let activeStyle = "py-4 bg-activeBlue text-white px-4 rounded-lg"
    let inActiveStyle = "py-4 text-white px-4 rounded-lg"

    return(
        <div className="basis-[12.343%] bg-primaryBlue flex flex-col items-center relative">
            <div className="w-full p-2 bg-primaryBlue">
                <div className="SidebarLogo flex justify-center items-center mt-4 borders">
                    <Link to="/">
                    <Image
                        width={150}
                        src={covaapd_logo}
                        preview={false}
                    />
                    </Link>
                </div>
            </div>
            <div className="SideBarItems mt-16 flex flex-col space-y-4">
                <>
                <NavLink 
                    to="/video" 
                    className={({isActive}) => 
                        isActive ? activeStyle : inActiveStyle
                    }
                >
                    <div className="flex flex-row justify-center items-center pr-6 ">
                        <Image
                            src={sidebar_video}
                            width={22}
                            preview={false}
                            className=""
                        />
                        <div className=" text-sidebarNavigation ml-2">Video</div>
                    </div>
                </NavLink>
                <NavLink 
                    to="/admin"
                    className={({isActive}) =>
                        isActive ? activeStyle : inActiveStyle
                    }
                >
                    <div className="flex flex-row justify-center items-center pr-6">
                        <Image
                            src={sidebar_admin}
                            width={22}
                            preview={false}
                            className=""
                        />
                        <div className=" text-sidebarNavigation ml-2">Admin</div>
                    </div>
                </NavLink>
                </>
            </div>
            <Dropdown
                menu={{items,}}
            >
                <div className="mt-32 bottom-60 flex flex-row justify-center items-center hover:bg-activeBlue p-4 rounded-lg">
                    <Space>
                        {
                        user && user.profile_pic && user.profile_pic.length > 0 ?
                        <div className="flex align-center justify-center h-8 w-8 rounded-full overflow-hidden">
                            <img src={user.profile_pic} alt={user.name} />
                        </div>
                        :
                        <Avatar size={32} icon={<UserOutlined />}
                            className="flex justify-center items-center"
                        />
                        }
                    </Space>
                    <div className="ml-2 text-white text-xs xl:text-xs">{ user ? user.name : ""}</div>
                    <div className="xl:ml-6 ml-2 text-white"><DownOutlined/></div>
                </div>
            </Dropdown>
            
        </div>
    )
}

export default Sidebar;